export type MapDataItem = {
  id: number;
  type: 'Feature';
  properties: {
    index: number;
    csv_name: string;
    alt_name?: string;
    is_country?: boolean;
  };
  geometry:
    | {
        type: 'Polygon';
        coordinates: [number, number][][];
      }
    | {
        type: 'MultiPolygon';
        coordinates: [number, number][][][];
      };
};
type MapData = MapDataItem[];

export type ParsedMapData = (MapDataItem & {
  center: [number, number][];
  perimeter: number;
  bBox: [[number, number], [number, number]];
})[];

export const getCityName = (properties: MapDataItem['properties']) => {
  let result = properties.alt_name || properties.csv_name;
  const fixes: [string, string][] = [
    [`מודיעיןמכביםרעות`, `מודיעין-מכבים-רעות`],
    [`מודיעין מכבים רעות`, `מודיעין-מכבים-רעות`],
  ];

  fixes.forEach(([nameToFix, fix]) => {
    if (result === nameToFix) result = fix;
  });

  return result;
};

const getBiggestPolygon = (polygons: [number, number][][][]): [number, number][] => {
  let biggestPerimeter = 0;
  let biggestPolygonCoords: [number, number][] = [[0, 0]];

  polygons.forEach((poligon) => {
    poligon.forEach((coords) => {
      const { perimeter } = parsePolygon(coords);
      if (perimeter > biggestPerimeter) {
        biggestPerimeter = perimeter;
        biggestPolygonCoords = coords;
      }
    });
  });

  return biggestPolygonCoords;
};

const parsePolygon = (
  coords: [number, number][],
): { center: [number, number][]; perimeter: number; bBox: [[number, number], [number, number]] } => {
  let minLat = coords[0][0];
  let maxLat = coords[0][0];
  let minLong = coords[0][1];
  let maxLong = coords[0][1];
  let avrgLat: number | undefined;
  let avrgLong: number | undefined;

  coords.forEach(([lat, long]) => {
    minLat = lat < minLat ? lat : minLat;
    minLong = long < minLong ? long : minLong;
    maxLat = lat > maxLat ? lat : maxLat;
    maxLong = long > maxLong ? long : maxLong;
    avrgLat = avrgLat !== undefined ? (avrgLat + lat) / 2 : lat;
    avrgLong = avrgLong !== undefined ? (avrgLong + long) / 2 : long;
  });

  if (avrgLat !== undefined && avrgLong !== undefined) {
    return {
      center: [[avrgLat, avrgLong]],
      perimeter:
        vLen([
          [minLat, minLong],
          [maxLat, minLong],
        ]) *
          2 +
        vLen([
          [maxLat, minLong],
          [maxLat, maxLong],
        ]) *
          2,
      bBox: [
        [minLong, minLat],
        [maxLong, maxLat],
      ],
    };
  } else {
    throw new Error('oops');
  }
};

const parseGeometry = (
  data: MapDataItem,
): { center: [number, number][]; perimeter: number; bBox: [[number, number], [number, number]] } => {
  let coords: [number, number][] = [[0, 0]];

  switch (data.geometry.type) {
    case 'Polygon':
      coords = data.geometry.coordinates[0];
      break;

    case 'MultiPolygon':
      // if (data.properties.csv_name === 'Palestine') debugger;
      coords = getBiggestPolygon(data.geometry.coordinates);
      // coords = data.geometry.coordinates[0][0];
      break;

    default:
      break;
  }

  return parsePolygon(coords);
};

const vLen = (vec: [number, number][]) => {
  return Math.sqrt((vec[0][0] - vec[1][0]) ** 2 + (vec[0][1] - vec[1][1]) ** 2);
};

let parsedData: ParsedMapData | null = null;
export const parseMap = (data: MapData): ParsedMapData => {
  if (parsedData) return parsedData;

  parsedData = data
    .map((featureData) => {
      const { center, perimeter, bBox } = parseGeometry(featureData);

      return {
        ...featureData,
        center,
        perimeter,
        bBox,
      };
    })
    .filter((d) => !!d) as ParsedMapData;

  return parsedData;
};
