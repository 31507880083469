import React from 'react';
import { format } from 'date-fns';

import css from './GeneralVoteInfo.module.scss';

interface Props {
  countryMaxVotesAmount: number;
  countryAlreadyVotedAmount: number;
  dataUpdateDate: string;
}

export const GeneralVoteInfo = (props: Props) => {
  const { countryMaxVotesAmount, countryAlreadyVotedAmount } = props;

  const renderGeneralVotedInfo = () => {
    const votesPercent =
      countryMaxVotesAmount > 0 && countryAlreadyVotedAmount > 0
        ? ((countryAlreadyVotedAmount / countryMaxVotesAmount) * 100).toFixed(2)
        : 0;
    const current = new Date(props.dataUpdateDate);
    const time = format(current, 'HH:mm');
    const date = format(current, 'd.MM.yy');
    return `נספרו ${votesPercent}% מכלל קולות המצביעים. מעודכן ל-${date} בשעה ${time}`;
  };

  return <div className={css.generalVoteInfo}>{renderGeneralVotedInfo()}</div>;
};
