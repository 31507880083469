export const PARTIES = [
  {
    shortName: 'מחל',
    fullName: 'הליכוד',
    color: '#699DE0',
  },
  {
    shortName: 'פה',
    fullName: 'יש עתיד',
    color: '#E7AC3A',
  },
  {
    shortName: 'כן',
    fullName: 'המחנה הממלכתי',
    color: '#9489DD',
  },
  {
    shortName: 'ט',
    fullName: 'הציונות הדתית',
    color: '#A5D356',
  },
  {
    shortName: 'שס',
    fullName: 'שס',
    color: '#8DD3C6',
  },
  {
    shortName: 'ג',
    fullName: 'יהדות התורה',
    color: '#DECF3A',
  },
  {
    shortName: 'ל',
    fullName: 'ישראל ביתנו',
    color: '#EB7B6E',
  },
  {
    shortName: 'אמת',
    fullName: 'העבודה',
    color: '#47679F',
  },
  {
    shortName: 'מרצ',
    fullName: 'מרצ',
    color: '#57AF83',
  },
  {
    shortName: 'ום',
    fullName: 'חד"ש תע"ל',
    color: '#BF68AD',
  },
  {
    shortName: 'עם',
    fullName: 'רע"ם',
    color: '#A35E5E',
  },
  {
    shortName: 'ב',
    fullName: 'הבית היהודי',
    color: '#7BD7E7',
  },
  {
    shortName: 'ד',
    fullName: 'בל"ד',
    color: '#608F97',
  },
  {
    shortName: 'other',
    fullName: 'אחר',
    color: '#BBBBBB',
  },
];
