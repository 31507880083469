import React from 'react';
import { useResponsive } from '@client/hooks/responsive';

import logoD from '@client/assets/images/logo-desktop.png';
import logoM from '@client/assets/images/logo-mobile.png';
import css from './Header.module.scss';

export const Header = () => {
  const [isDesktop] = useResponsive(['DESKTOP']);

  return (
    <header className={css.header}>
      <img src={isDesktop ? logoD : logoM} alt="תוצאות ארציות 2022" className={css.logo} />
    </header>
  );
};
