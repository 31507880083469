import React from 'react';
import _ from 'lodash';
import { CDN_URL /*CUSTOM_DOMAIN_URL*/ } from '@common/constants';
import { withIframeAutoHeight } from '@client/components/HOCs/with-iframe-autoheight';
import { Header } from '@client/components/common/Header';
import { GeneralResult } from '@client/components/common/GeneralResult';
import { Map } from '@client/components/common/Map';
import { Popup } from '@client/components/common/Popup';
import { Search } from '@client/components/common/Search';
import { GeneralVoteInfo } from '@client/components/common/GeneralVoteInfo';
import { MapDataItem } from '@client/components/common/Map/helpers';
import { analytics } from '@client/utils/analytics';
import { Model as StatisticsModel } from '@template/common/src/models/statistics';
import { Model as SettingsModel } from '@template/common/src/models/settings';

import css from './Main.module.scss';

const INITIAL_CITY_DATA = { csv_name: '', alt_name: '', index: -1 };

async function makeRequest<T>(url: string) {
  const response = await fetch(url);
  const resBody = await response.json();
  const result: T = resBody.data || resBody;

  return result;
}

const Main = () => {
  const [data, setData] = React.useState<StatisticsModel | null>(null);
  const [geoData, setGeoData] = React.useState<MapDataItem[] | null>(null);
  const [isVotingFinished, setIsVotingFinished] = React.useState<boolean>(false);
  const [oldData, setOldData] = React.useState<StatisticsModel | null>(null);
  const [city, setCity] = React.useState<MapDataItem['properties']>(INITIAL_CITY_DATA);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const [stats, geoJSON, settings] = await Promise.all([
          makeRequest<StatisticsModel>(`${CDN_URL}/stats.json`),
          makeRequest<MapDataItem[]>(`${CDN_URL}/geo.json`),
          makeRequest<SettingsModel['main']>(`${CDN_URL}/settings.json?v=1`),
        ]);

        setData(stats);
        setGeoData(geoJSON);
        setIsVotingFinished(settings.isVotingFinished);
      } catch (e) {
        console.error('Fetch Data Fails: ', (e as Error).message || e);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (isVotingFinished) {
      const fetchOldData = async () => {
        try {
          const oldStats = await makeRequest<StatisticsModel>(`${CDN_URL}/stats-old.json?v=1`);
          setOldData(oldStats);
        } catch (e) {
          console.error('Fetch Data Fails: ', (e as Error).message || e);
        }
      };

      fetchOldData();
    }
  }, [isVotingFinished]);

  const handleCityChange = (city: MapDataItem['properties']) => {
    setCity(city);
    analytics.gtag.event(city.alt_name ?? city.csv_name, 'city search');
  };

  const handleCitySearch = (city: string) => {
    const currCityProps = _.find(geoData, { properties: { csv_name: city } });
    const altName = currCityProps?.properties.alt_name || city;
    setCity({ ...INITIAL_CITY_DATA, csv_name: city, alt_name: altName });
    analytics.gtag.event(altName ?? city, 'search');
  };

  const handleResetCity = () => {
    setCity(INITIAL_CITY_DATA);
  };

  return (
    <div className={css.main}>
      <Header />
      {!data ? (
        <div className={css.preloader}>
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        <>
          {data && (
            <GeneralResult
              countryVerifiedVotesAmount={data.countryVerifiedVotesAmount}
              statsByParty={data.statsByParty}
            />
          )}
          {data && (
            <GeneralVoteInfo
              dataUpdateDate={data.updatedAt}
              countryMaxVotesAmount={data.countryMaxVotesAmount}
              countryAlreadyVotedAmount={data.countryAlreadyVotedAmount}
            />
          )}
          {geoData && data.statsByCity && (
            <div className={css.map}>
              <Map statsByCity={data.statsByCity} geoData={geoData} currentCity={city} setCity={handleCityChange} />
              {city.csv_name && (
                <Popup
                  statsByCity={data.statsByCity}
                  statsByCityOld={oldData?.statsByCity}
                  selectedCity={city}
                  onClose={handleResetCity}
                />
              )}
              {!city.csv_name && (
                <Search statsByCity={data.statsByCity} geoData={geoData} onChange={handleCitySearch} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withIframeAutoHeight(Main);
