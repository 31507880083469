import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { MAIN_PAGE_ROUTE } from '@client/constants/routes';
import { Head } from '@client/components/common/Head';
import Main from '@client/components/pages/Main';

import '@client/styles/index.scss';
import css from './App.module.scss';

export const App = () => {
  return (
    <div className={css.app}>
      <Head />
      <Routes>
        <Route path={MAIN_PAGE_ROUTE} element={<Main />} />
      </Routes>
    </div>
  );
};
