/* eslint-disable react/jsx-no-target-blank */
// TODO: without this ts comments build fails. figure out why, fix, and remove this ts checks disable
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import { LeafletMouseEvent } from 'leaflet';
import { Map as MapType, useMapEvents, MapContainer, GeoJSON, GeoJSONProps, SVGOverlay } from 'react-leaflet';
import * as Statistics from '@common/models/statistics';
import { PARTIES } from '@client/constants/parties';

import { parseMap, getCityName, ParsedMapData, MapDataItem } from './helpers';
import css from './Map.module.scss';

const position: [number, number] = [32, 35.0];
const INITAIL_ZOOM = 10;
const MIN_ZOOM = 10;
const MAX_ZOOM = 14;

interface Props {
  geoData: MapDataItem[];
  statsByCity: Statistics.StatisticItem[];
  setCity: (cityProps: MapDataItem['properties']) => void;
  currentCity: MapDataItem['properties'];
}
type CityColorsItem = {
  city: string;
  shortName?: string;
  color?: string;
};
export const Map = (props: Props) => {
  const { geoData, currentCity } = props;
  const [currentZoom, setCurrentZoom] = React.useState(INITAIL_ZOOM);
  const [parsedData, setParsedData] = React.useState<ParsedMapData>(null);
  const mapRef = React.useRef<MapType>(null);

  React.useEffect(() => {
    if (geoData) {
      setParsedData(parseMap(geoData));
    }
  }, [geoData]);

  React.useEffect(() => {
    const originalAttribution = document.querySelector('.leaflet-control-attribution.leaflet-control');

    originalAttribution?.remove();
  }, []);

  const cityColors = React.useMemo(() => {
    return _.reduce(
      props.statsByCity,
      (result: CityColorsItem[], item) => {
        const mostVotedParty = _.maxBy(item.p, 'v');
        const additionalInfo = _.find(PARTIES, {
          shortName: mostVotedParty?.n,
        });

        result.push({
          ..._.omit(additionalInfo, 'fullName'),
          city: item.c,
        });
        return result;
      },
      [],
    );
  }, [props.statsByCity]);

  const getColor = (cityName: string) => {
    const current = _.find(cityColors, { city: cityName });
    return current?.color || '#e3dede';
  };

  const onAreaClick = (e: LeafletMouseEvent) => {
    props.setCity((e.target.feature as MapDataItem).properties);
  };

  const onZoomChanged = React.useCallback((zoom: number) => {
    setCurrentZoom(zoom);
  }, []);
  const labelSize = Math.floor(11 + 9 * ((currentZoom - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)));

  return (
    <div className={css.leafletMap}>
      {_.size(cityColors) !== 0 && !_.isEmpty(geoData) && (
        <MapContainer
          center={position}
          minZoom={MIN_ZOOM}
          zoom={INITAIL_ZOOM}
          maxZoom={MAX_ZOOM}
          scrollWheelZoom={true}
          className={css.map}
          ref={mapRef}
        >
          {parsedData && <FlyToCityController cities={parsedData} currentCity={currentCity} />}
          <ZoomListener onZoomChange={onZoomChanged} />
          <GeoJSON
            data={geoData as unknown as GeoJSONProps['data']}
            style={(feature) => {
              const isCountry = feature?.properties?.is_country;

              return {
                className: isCountry ? css.pathCountry : css.pathCity,
                weight: 1,
                opacity: 1,
                color: 'white',
                fillOpacity: isCountry ? 1 : 0.75,
                fillColor: isCountry ? '#f6f6f4' : getColor(feature?.properties.csv_name || ''),
              };
            }}
            onEachFeature={(feature, layer) => {
              // layer.setStyle({ fillColor: '#f7941d' });
              if (!feature?.properties?.is_country) {
                layer.on('click', onAreaClick);
              }
            }}
          />
          {parsedData && (
            <>
              {parsedData.map((d) => {
                if (d.properties.is_country) return;
                if (d.perimeter > 0.25 / (currentZoom / INITAIL_ZOOM) ** (2 + (currentZoom / INITAIL_ZOOM) * 10)) {
                  return (
                    <SVGOverlay attributes={{ stroke: 'red', overflow: 'visible' }} bounds={d.bBox} key={d.id}>
                      <text x="50%" y="50%" className={css.regionLabel} fontSize={labelSize}>
                        {getCityName(d.properties)}
                      </text>
                    </SVGOverlay>
                  );
                }
              })}
              <Creds />
            </>
          )}
        </MapContainer>
      )}
    </div>
  );
};

type ZoomListenerProps = {
  onZoomChange: (zoom: number) => unknown;
};

const ZoomListener = ({ onZoomChange }: ZoomListenerProps) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      onZoomChange(mapEvents.getZoom());
    },
  });

  return null;
};

type FlyToCityControllerProps = {
  currentCity: MapDataItem['properties'];
  cities: ParsedMapData;
};

const FlyToCityController: React.FC<FlyToCityControllerProps> = ({ cities, currentCity }) => {
  const mapEvents = useMapEvents({});

  React.useEffect(() => {
    const cityInfo = _.find(cities, (c) => c.properties.csv_name === currentCity.csv_name);

    if (cityInfo) {
      mapEvents.flyToBounds(cityInfo.bBox, { maxZoom: (MAX_ZOOM - MIN_ZOOM) / 2 + MIN_ZOOM, duration: 0.3 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCity]);

  return null;
};

const Creds = () => {
  return (
    <div className={css.creds}>
      <div className={css.credsItem}>
        <span>Map by </span>
        <a href="https://leafletjs.com/" rel="nofollow noopener noreferrer" target="_blank">
          Leaflet
        </a>
      </div>
      <div className={css.divider} />
      <div className={css.credsItem}>
        <span>Developed by </span>
        <a href="https://www.rabbi.co.il/" target="_blank">
          Rabbi Interactive
        </a>
      </div>
    </div>
  );
};
