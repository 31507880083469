/* eslint-disable max-len,react/jsx-tag-spacing */
import React from 'react';
import classNames from 'classnames';
import css from './Icon.module.scss';

interface Props {
  type: string;
  className?: string;
  // width?: number;
  // height?: number;
  // color?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const Icon = ({ type, className, onClick, ...iconProps }: Props) => {
  let icon = null;
  // const svgProps = {
  //   width,
  //   height,
  //   color,
  //   viewBox: `0 0 ${width || 38} ${height || 38}`,
  //   xmlns: 'http://www.w3.org/2000/svg',
  // };

  switch (type) {
    case 'indicator': {
      icon = (
        <svg width="9px" height="8px" viewBox="0 0 9 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Mobile_map_pop_up" transform="translate(-200.000000, -578.000000)" fill="#7FAC67">
              <g id="Group-5-Copy" transform="translate(65.000000, 538.000000)">
                <polygon id="Rectangle" points="139.400702 40 143.481699 48 135.188883 48"></polygon>
              </g>
            </g>
          </g>
        </svg>
      );
      break;
    }
    case 'close': {
      icon = (
        <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop_popup" transform="translate(-936.000000, -692.000000)" fill="#122536">
              <g id="Bitmap" transform="translate(583.000000, 671.000000)">
                <g id="Group-5-Copy" transform="translate(346.000000, 12.000000)">
                  <path
                    d="M16.5232656,10.0807958 C16.7808752,10.3386747 16.7808752,10.7557039 16.5237525,11.0130959 L13.0884252,14.4512979 L16.5237525,17.8911594 C16.7808752,18.1485514 16.7808752,18.5655807 16.5237525,18.8229726 C16.2669775,19.0800165 15.850445,19.0802345 15.5934011,18.8234595 L12.1564252,15.3832979 L8.72204647,18.8229726 C8.46527148,19.0800165 8.04873896,19.0802345 7.79169505,18.8234595 C7.53408545,18.5655807 7.53408545,18.1485514 7.79120813,17.8911594 L11.2254252,14.4512979 L7.79120813,11.0130959 C7.53408545,10.7557039 7.53408545,10.3386747 7.79120813,10.0812827 C8.04798312,9.82423879 8.46451564,9.82402079 8.72155955,10.0807958 L12.1574252,13.5192979 L15.5929142,10.0812827 C15.8496891,9.82423879 16.2662217,9.82402079 16.5232656,10.0807958 Z"
                    id="color"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
      break;
    }
    case 'search': {
      icon = (
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Mobile_map_first-view" transform="translate(-121.000000, -577.000000)" fill="#122536">
              <path
                d="M126.60455,586.84806 C124.264759,586.84806 122.36079,584.944495 122.36079,582.604408 C122.36079,580.264676 124.264759,578.361111 126.60455,578.361111 C128.944697,578.361111 130.84831,580.264676 130.84831,582.604408 C130.84831,584.944495 128.944697,586.84806 126.60455,586.84806 M134.280146,591 C134.472463,591 134.653048,590.924995 134.788843,590.788848 C134.924993,590.653413 135,590.472476 135,590.279454 C135,590.087142 134.924993,589.906561 134.788843,589.770059 L131.053424,586.034735 L131.279156,585.693124 C131.887388,584.775289 132.209456,583.707089 132.209456,582.604408 C132.209456,579.51427 129.694767,577 126.60455,577 C123.514334,577 121,579.51427 121,582.604408 C121,585.694901 123.514334,588.209171 126.60455,588.209171 C127.70797,588.209171 128.775842,587.887822 129.693345,587.279606 L130.034253,587.052813 L133.770383,590.788493 C133.906533,590.924995 134.08783,591 134.280146,591"
                id="color"
              ></path>
            </g>
          </g>
        </svg>
      );
      break;
    }

    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps} onClick={onClick}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  onClick: () => null,
};

export default Icon;
