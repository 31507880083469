export const FIREBASE_CONFIG = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
};
const WEBSERVER_PORT = process.env.WEBSERVER_PORT;
export const IS_DEV = process.env.IS_DEV === 'true';
export const PROJECT_ID = 'live-election-map-prod-7ap8s';
export const CDN_URL = 'https://electionmapstorage.rabbicdn.com';
export const CUSTOM_DOMAIN_URL = IS_DEV ? `http://localhost:${WEBSERVER_PORT}` : 'https://rabbicdn.com';
export const API_URL = IS_DEV ? `http://localhost:${WEBSERVER_PORT}/api` : `/api`;
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';
export const LIST_OF_PARTIES = [
  'אמת',
  'ב',
  'ג',
  'ודעם',
  'ז',
  'זץ',
  'ט',
  'י',
  'יז',
  'ינ',
  'יף',
  'יק',
  'יר',
  'כ',
  'כך',
  'כן',
  'ל',
  'מחל',
  'מרצ',
  'נ',
  'ני',
  'נר',
  'עם',
  'פה',
  'ףז',
  'צי',
  'צי',
  'צף',
  'ץ',
  'ק',
  'קי',
  'קך',
  'קץ',
  'ר',
  'רנ',
  'רף',
  'רק',
  'שס',
  'ת',
];
