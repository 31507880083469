import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as UserModel } from '@common/models/user';
import * as actions from './actions';

export interface User extends UserModel {
  auth: {
    isInProgress: boolean;
    isLoggedIn: boolean;
    error: string | null;
  };
}

const initialState = {
  uid: '',
  role: 'REGULAR_USER',
  auth: {
    isInProgress: false,
    isLoggedIn: false,
    error: null,
  },
} as User;

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.name = action.payload.name;
      state.auth.isLoggedIn = true;
    },
  },
});

export const userActions = user.actions;
export default user.reducer;
