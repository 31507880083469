export const analytics = {
  gtag: {
    event: (action: string, category: string, label = '') => {
      // @ts-expect-error gtag is added in html.head:
      const { gtag } = window;

      if (gtag) {
        const params = Object.assign({ event_category: category }, label && { event_label: label });

        gtag('event', action, params);
        console.log('gtag:', action, params);
      } else {
        console.log('%cgtag is undefined', 'color: red');
      }
    },
  },
};
