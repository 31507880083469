import React from 'react';

const componentDidMount = (effect: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(effect, []);
};

export const withIframeAutoHeight = (Component: React.ComponentType<any>) => {
  function WrappedComponent(props: any) {
    const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null);

    const observer: any = React.useCallback(() => {
      requestAnimationFrame(observer);

      const height = containerRef && containerRef.current ? containerRef.current.offsetHeight : 0;
      const parentHeight = window.innerHeight;

      const isDiff = height !== parentHeight && Math.abs(height - parentHeight) > 3;
      const isFrame = window !== window.parent;

      if (!isDiff) {
        return;
      }

      if (isFrame) {
        window.parent.postMessage(
          {
            // eslint-disable-next-line
						payload: { iframeHeight: height + '' },
            source: 'live-election-map',
            type: 'resize',
            sender: 'LIVE_ELECTION_MAP',
          },
          '*',
        );
      } else {
        document.documentElement.style.height = `${height}px`;
        document.body.style.height = `${height}px`;

        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
      }
    }, [containerRef]);

    componentDidMount(() => {
      requestAnimationFrame(observer);
      return () => cancelAnimationFrame(observer);
    });

    return (
      <div ref={containerRef}>
        <Component {...props} />
      </div>
    );
  }

  WrappedComponent.displayName = `withIframeAutoHeight(${Component.name})`;

  return WrappedComponent;
};

// export default withIframeAutoHeight;
