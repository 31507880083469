import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { StatisticItem } from '@common/models/statistics';
import { useOnClickOutside } from '@client/hooks/click-outside';
import { MapDataItem } from '@client/components/common/Map/helpers';

import Icon from '../Icon';

import css from './Search.module.scss';

interface Props {
  statsByCity: StatisticItem[];
  geoData: MapDataItem[];
  className?: string;
  onChange: (val: string, isFromAutocomplete?: boolean) => void;
}

export const Search: React.FC<Props> = ({ className = '', onChange, statsByCity, geoData }) => {
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [isSuggestionsListVisible, setIsSuggestionsListVisible] = React.useState(false);
  const [value, setValue] = React.useState('');

  const placeholder = 'חפשו איך השכנים שלכם הצביעו';
  const title = 'איך הצביעו ביישוב שלכם?';

  const cities = React.useMemo(() => {
    const geoSities = _.reduce(
      geoData,
      (
        result: {
          csvCity: string;
          altCity: string;
        }[],
        item,
      ) => {
        result.push({
          csvCity: item.properties.csv_name,
          altCity: item.properties.alt_name || item.properties.csv_name,
        });
        return result;
      },
      [],
    );

    return _.reduce(
      statsByCity,
      (
        result: {
          csvCity?: string;
          altCity: string;
        }[],
        item,
      ) => {
        const geoCity = _.find(geoSities, { csvCity: item.c });

        result.push({
          csvCity: geoCity?.csvCity || item.c,
          altCity: geoCity?.altCity || item.c,
        });
        return result;
      },
      [],
    );
  }, [statsByCity, geoData]);

  const suggestionsList = React.useMemo(() => {
    if (cities) {
      return [..._.filter(cities, (n) => n.altCity && n.altCity.substring(0, value.length) === value)];
    }
  }, [cities, value]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleClickOutside = () => {
    setIsSuggestionsListVisible(false);
  };

  useOnClickOutside<HTMLDivElement>(searchRef, handleClickOutside);

  const onInputFocus = () => {
    setIsSuggestionsListVisible(true);
  };

  const handelSearchIconClick = () => {
    setIsSuggestionsListVisible(false);
  };

  const onSuggestedItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { cityCsv } = e.currentTarget.dataset;

    onChange(cityCsv as string, true /* isFromAutocomplete */);
    setIsSuggestionsListVisible(false);
    setValue('');
  };

  return (
    <div className={cn(css.search, className)} ref={searchRef}>
      <p className={css.title}>{title}</p>
      <div className={css.inputWrap}>
        <input type="text" placeholder={placeholder} onFocus={onInputFocus} onChange={onSearchChange} value={value} />
        <button type="button" className={css.searchIconBtn} onClick={handelSearchIconClick}>
          <Icon className={css.searchIcon} type="search" />
        </button>
      </div>

      {isSuggestionsListVisible && cities.length !== 0 && (
        <div className={css.suggestionsList}>
          <div className={css.suggestionsListInner}>
            {_.map(
              suggestionsList,
              (i: { csvCity: string; altCity: string }) =>
                i.csvCity && (
                  <div
                    key={i.csvCity}
                    onClick={onSuggestedItemClick}
                    className={css.item}
                    data-city-csv={i.csvCity}
                    dangerouslySetInnerHTML={{ __html: getStrWithHighlightCharacters(i.altCity, value) }}
                  />
                ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const getStrWithHighlightCharacters = (text: string, word: string) => {
  const substring = new RegExp(word, 'gi');

  return text.replace(substring, (match) => `<span>${match}</span>`);
};
