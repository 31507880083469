import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { AnyAction } from '@reduxjs/toolkit';
import fetch from 'isomorphic-fetch';

import { API_URL } from '@common/constants';

const api = createApi({
  reducerPath: 'api',
  tagTypes: [],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, fetchFn: fetch }),
  extractRehydrationInfo(action: AnyAction) {
    if (action.type === 'HYDRATE') {
      return action.payload;
    }
  },
  endpoints: () => ({}),
});

export const {
  util: { getRunningOperationPromises },
} = api;
export default api;
