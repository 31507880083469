import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { StatisticItem } from '@common/models/statistics';
import { PARTIES } from '@client/constants/parties';
import { getCityName, MapDataItem } from '@client/components/common/Map/helpers';
import { thousandSeparator } from '@client/utils/thousand-separator';

import Icon from '../Icon';
import css from './Popup.module.scss';

interface Props {
  statsByCity: StatisticItem[];
  statsByCityOld?: StatisticItem[];
  selectedCity: MapDataItem['properties'];
  onClose: () => void;
}

export const Popup = (props: Props) => {
  const { selectedCity, statsByCity, onClose, statsByCityOld } = props;
  const [isMounted, setIsMounted] = React.useState(false);
  const lessLabel = 'ירידה מהסבב הקודם';
  const largerLabel = 'עלייה מהסבב הקודם';
  const noDataMessage = 'עדיין אין תוצאות בעיר זו';

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const fullPartyData = React.useMemo(() => {
    const currentCityData = _.find(statsByCity, { c: selectedCity.csv_name });
    if (currentCityData) {
      const onePercent = currentCityData.tv / 100;
      return _.reduce(
        currentCityData.p,
        (
          result: {
            shortName?: string;
            fullName?: string;
            color?: string;
            votesPercent: number;
          }[],
          item,
        ) => {
          const votesPercent = onePercent > 0 ? Number((item.v / onePercent).toFixed(2)) : 0;

          if (votesPercent >= 3.25) {
            const additionalInfo = _.find(PARTIES, {
              shortName: item.n,
            });

            if (additionalInfo) {
              result.push({
                ...additionalInfo,
                votesPercent: votesPercent,
              });
            }
          }
          return result;
        },
        [],
      );
    }
  }, [statsByCity, selectedCity]);

  const renderPartyRow = (
    item: { shortName?: string; fullName?: string; color?: string; votesPercent: number },
    index: number,
  ) => {
    return (
      <div className={css.row} key={index}>
        <div className={css.partyName}>{`${item.fullName} ${item.votesPercent}%`}</div>
        <div
          className={css.coloredBlock}
          style={{ width: isMounted ? `${item.votesPercent}%` : '0%', color: item.color }}
        />
      </div>
    );
  };

  const renderVotingActivity = () => {
    const oldCityData = _.find(statsByCityOld, { c: selectedCity.csv_name });
    const currentCityData = _.find(statsByCity, { c: selectedCity.csv_name });
    let currentPercent;
    let isLarger;

    if (currentCityData) {
      currentPercent = ((currentCityData.tv / currentCityData.mv) * 100).toFixed(2);
    }

    if (oldCityData && currentPercent) {
      const oldPercent = ((oldCityData.tv / oldCityData.mv) * 100).toFixed(2);
      isLarger = currentPercent >= oldPercent;
    }

    return oldCityData ? (
      <div className={css.votingActivity}>
        <span>{`אחוז ההצבעה: ${currentPercent}%`}</span>
        <div className={cn(css.indicator, isLarger && css.isLarger)}>
          <Icon type="indicator" className={css.indicatorIcon} />
          <span>{isLarger ? largerLabel : lessLabel}</span>
        </div>
      </div>
    ) : (
      currentCityData && (
        <div className={css.votingActivity}>
          <span>{`נספרו עד כה ${thousandSeparator(currentCityData.tv)} קולות בעיר.`}</span>
        </div>
      )
    );
  };

  return (
    <div className={css.popup}>
      <p className={css.title}>{getCityName(selectedCity)}</p>
      {renderVotingActivity()}
      {fullPartyData ? (
        <div className={css.partiesList}>
          {_.map(_.orderBy(fullPartyData, 'votesPercent', 'desc'), (party, index) => renderPartyRow(party, index))}
          {renderPartyRow(
            {
              ...PARTIES[PARTIES.length - 1],
              votesPercent: Number((100 - _.sumBy(fullPartyData, 'votesPercent')).toFixed(2)),
            },
            9999999,
          )}
        </div>
      ) : (
        <div className={css.votingActivity}>{noDataMessage}</div>
      )}
      <Icon type="close" className={css.closeIcon} onClick={() => onClose()} />
    </div>
  );
};
