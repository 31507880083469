import React from 'react';
import cn from 'classnames';
import { useResponsive } from '@client/hooks/responsive';

import arrowDown from '@client/assets/images/arrow-down.png';
import { PARTIES } from '@client/constants/parties';
import { analytics } from '@client/utils/analytics';

import css from './GeneralResult.module.scss';
import _ from 'lodash';

interface Props {
  statsByParty: { n: string; v: number }[];
  countryVerifiedVotesAmount: number;
}

export const GeneralResult = (props: Props) => {
  const [isDesktop] = useResponsive(['DESKTOP']);
  const [isFullHeight, setIsFullHeight] = React.useState<boolean>(false);
  const moreHandlerLabel = isFullHeight ? 'סגור' : 'לתוצאות הארציות המלאות';
  const ROW_HEIGHT = 29;
  const COLORED_BLOCK_WIDTH = isDesktop ? 337 : 290;

  const fullPartyData = React.useMemo(() => {
    const onePercent = props.countryVerifiedVotesAmount / 100;

    return _.reduce(
      props.statsByParty,
      (
        result: {
          shortName?: string;
          fullName?: string;
          color?: string;
          votesPercent: number;
        }[],
        item,
      ) => {
        const votesPercent = onePercent > 0 ? Number((item.v / onePercent).toFixed(2)) : 0;

        if (votesPercent >= 3.25) {
          const additionalInfo = _.find(PARTIES, {
            shortName: item.n,
          });

          result.push({
            ...additionalInfo,
            votesPercent: votesPercent,
          });
        }
        return result;
      },
      [],
    );
  }, [props.statsByParty, props.countryVerifiedVotesAmount]);

  const onViewMoreBtnClick = () => {
    setIsFullHeight(!isFullHeight);

    if (!isFullHeight) {
      analytics.gtag.event('full results', 'election map');
    }
  };

  const renderRow = (
    item: { shortName?: string; fullName?: string; color?: string; votesPercent: number },
    index: number,
  ) => {
    return (
      item.fullName && (
        <div className={css.row} key={index}>
          <div className={css.partyName}>{item.fullName}</div>
          <div className={css.visualPart} style={{ color: item.color }}>
            <div
              className={css.coloredBlock}
              style={{ width: `${(COLORED_BLOCK_WIDTH / 100) * item.votesPercent}%` }}
            />
            <div className={css.percent}>{`${item.votesPercent}%`}</div>
          </div>
        </div>
      )
    );
  };
  return (
    <div className={cn(css.generalResult, isFullHeight && css.isFullHeight)}>
      <div className={css.list} style={isFullHeight ? { height: `${ROW_HEIGHT * (_.size(fullPartyData) + 1)}px` } : {}}>
        {_.map(_.orderBy(fullPartyData, 'votesPercent', 'desc'), (party, index) => renderRow(party, index))}
        {renderRow(
          {
            ...PARTIES[PARTIES.length - 1],
            votesPercent: Number((100 - _.sumBy(fullPartyData, 'votesPercent')).toFixed(2)),
          },
          9999999,
        )}
      </div>

      <div className={css.moreHandler} onClick={onViewMoreBtnClick}>
        <span>{moreHandlerLabel}</span>
        <div style={{ backgroundImage: `url(${arrowDown})` }} className={css.iconArrowDown} />
      </div>
    </div>
  );
};
